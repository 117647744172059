import React, {Component} from "react"

class ArbitrumLogo extends Component {

	render() {

		return (
            <svg width={this.props.size} height={this.props.size} viewBox="0 0 470.287 514.251" x="0px" y="0px" fill="none">
                <g id="Background">
                </g>
                <g id="Logos_and_symbols">
                    <g id="SYMBOL_VER_3">
                    </g>
                    <g id="SYMBOL_VER_3_3_">
                    </g>
                    <g id="SYMBOL_VER_4">
                    </g>
                    <g id="SYMBOL_VER_4_1_">
                        <g id="SYMBOL_VER_4_3_">
                        </g>
                    </g>
                    <g id="SYMBOL_VER_5_1_">
                    </g>
                    <g id="off_2_1_">
                    </g>
                    <g id="VER_3_1_">
                        <g id="SYMBOL_VER_2_1_">
                        </g>
                    </g>
                    <g id="VER_3">
                        <g id="SYMBOL_VER_2">
                        </g>
                    </g>
                    <g id="off_2">
                    </g>
                    <g id="SYMBOL_VER_5">
                    </g>
                    <g id="SYMBOL_VER_1">
                    </g>
                    <g id="SYMBOL_VER_1_1_">
                    </g>
                    <g id="SYMBOL_VER_1-1_3_">
                    </g>
                    <g id="SYMBOL_VER_1-1_2_">
                    </g>
                    <g id="SYMBOL_VER_1-1">
                    </g>
                    <g id="SYMBOL_VER_1-1_1_">
                        <g id="_x31_-3">
                        </g>
                        <g id="Symbol_-_Original_14_">
                            <path fill="#2D374B" d="M291.134,237.469l35.654-60.5l96.103,149.684l0.046,28.727l-0.313-197.672
                                c-0.228-4.832-2.794-9.252-6.887-11.859L242.715,46.324c-4.045-1.99-9.18-1.967-13.22,0.063c-0.546,0.272-1.06,0.57-1.548,0.895
                                l-0.604,0.379L59.399,144.983l-0.651,0.296c-0.838,0.385-1.686,0.875-2.48,1.444c-3.185,2.283-5.299,5.66-5.983,9.448
                                c-0.103,0.574-0.179,1.158-0.214,1.749l0.264,161.083l89.515-138.745c11.271-18.397,35.825-24.323,58.62-24.001l26.753,0.706
                                L67.588,409.765l18.582,10.697L245.692,157.22l70.51-0.256L157.091,426.849l66.306,38.138l7.922,4.556
                                c3.351,1.362,7.302,1.431,10.681,0.21l175.453-101.678l-33.544,19.438L291.134,237.469z M304.736,433.395l-66.969-105.108
                                l40.881-69.371l87.952,138.628L304.736,433.395z"/>
                            <polygon fill="#28A0F0" points="237.768,328.286 304.736,433.395 366.601,397.543 278.648,258.915 			"/>
                            <path fill="#28A0F0" d="M422.937,355.379l-0.046-28.727l-96.103-149.684l-35.654,60.5l92.774,150.043l33.544-19.438
                                c3.29-2.673,5.281-6.594,5.49-10.825L422.937,355.379z"/>
                            <path fill="#FFFFFF" d="M20.219,382.469l47.369,27.296l157.634-252.801l-26.753-0.706c-22.795-0.322-47.35,5.604-58.62,24.001
                                L50.334,319.004l-30.115,46.271V382.469z"/>
                            <polygon fill="#FFFFFF" points="316.202,156.964 245.692,157.22 86.17,420.462 141.928,452.565 157.091,426.849 			"/>
                            <path fill="#96BEDC" d="M452.65,156.601c-0.59-14.746-8.574-28.245-21.08-36.104L256.28,19.692
                                c-12.371-6.229-27.825-6.237-40.218-0.004c-1.465,0.739-170.465,98.752-170.465,98.752c-2.339,1.122-4.592,2.458-6.711,3.975
                                c-11.164,8.001-17.969,20.435-18.668,34.095v208.765l30.115-46.271L50.07,157.921c0.035-0.589,0.109-1.169,0.214-1.741
                                c0.681-3.79,2.797-7.171,5.983-9.456c0.795-0.569,172.682-100.064,173.228-100.337c4.04-2.029,9.175-2.053,13.22-0.063
                                l173.022,99.523c4.093,2.607,6.659,7.027,6.887,11.859v199.542c-0.209,4.231-1.882,8.152-5.172,10.825l-33.544,19.438
                                l-17.308,10.031l-61.864,35.852l-62.737,36.357c-3.379,1.221-7.33,1.152-10.681-0.21l-74.228-42.693l-15.163,25.717
                                l66.706,38.406c2.206,1.255,4.171,2.367,5.784,3.272c2.497,1.4,4.199,2.337,4.8,2.629c4.741,2.303,11.563,3.643,17.71,3.643
                                c5.636,0,11.132-1.035,16.332-3.072l182.225-105.531c10.459-8.104,16.612-20.325,17.166-33.564V156.601z"/>
                        </g>
                        <g id="Symbol_-_Original_13_">
                        </g>
                        <g id="Symbol_-_Original_6_">
                        </g>
                        <g id="Symbol_-_Original_4_">
                        </g>
                        <g id="One_color_version_-_White_3_">
                            <g id="Symbol_-_Original_15_">
                            </g>
                        </g>
                        <g id="One_color_version_-_White">
                            <g id="Symbol_-_Original">
                            </g>
                        </g>
                        <g id="Symbol_-_Monochromatic_3_">
                            <g id="_x33__7_">
                            </g>
                        </g>
                        <g id="Symbol_-_Monochromatic">
                            <g id="_x33__3_">
                            </g>
                        </g>
                        <g id="_x33__2_">
                        </g>
                        <g id="_x33__1_">
                        </g>
                        <g id="_x33_">
                        </g>
                        <g id="Symbol_-_Original_10_">
                        </g>
                        <g id="Symbol_-_Original_1_">
                        </g>
                        <g id="Symbol_-_Original_2_">
                        </g>
                        <g id="_x34__1_">
                        </g>
                        <g id="Symbol_-_Monochromatic_2_">
                            <g id="_x33__6_">
                            </g>
                        </g>
                        <g id="One_color_version_-_White_2_">
                            <g id="Symbol_-_Original_11_">
                            </g>
                        </g>
                        <g id="Symbol_-_Original_5_">
                            <g id="Symbol_-_Original_12_">
                            </g>
                        </g>
                        <g id="One_color_version_-_White_1_">
                            <g id="Symbol_-_Original_9_">
                            </g>
                        </g>
                    </g>
                    <g id="SYMBOL_VER_1_2_">
                        <g id="SYMBOL_VER_2_4_">
                        </g>
                        <g id="SYMBOL_VER_2-1-1_1_">
                        </g>
                        <g id="SYMBOL_VER_2-2-1_1_">
                        </g>
                        <g id="SYMBOL_VER_2-3-1_4_">
                        </g>
                        <g id="New_Symbol_1_">
                            <g id="SYMBOL_VER_2-3-1_3_">
                            </g>
                        </g>
                        <g id="New_Symbol">
                            <g id="SYMBOL_VER_2-3-1_1_">
                            </g>
                        </g>
                    </g>
                    <g id="SYMBOL_VER_2_2_">
                    </g>
                    <g id="SYMBOL_VER_4_2_">
                    </g>
                    <g id="SYMBOL_VER_3_2_">
                    </g>
                    <g id="SYMBOL_VER_3_1_">
                    </g>
                    <g id="SYMBOL_VER_1-1-1_1_">
                    </g>
                    <g id="SYMBOL_VER_1-1-1">
                    </g>
                    <g id="SYMBOL_VER_1-1-1_2_2_">
                    </g>
                    <g id="SYMBOL_VER_1-1-1_2">
                    </g>
                    <g id="SYMBOL_VER_1-1-1_2_1_">
                    </g>
                    <g id="Symbol_-_Original_7_">
                    </g>
                    <g id="Symbol_-_Original_8_">
                    </g>
                    <g id="SYMBOL_VER_2-1-1">
                    </g>
                    <g id="SYMBOL_VER_2-2-1">
                    </g>
                    <g id="SYMBOL_VER_2-3-1">
                    </g>
                    <g id="SYMBOL_VER_5-1_1_">
                    </g>
                    <g id="SYMBOL_VER_5-1">
                    </g>
                    <g id="SYMBOL_VER_5-2_1_">
                    </g>
                    <g id="SYMBOL_VER_5-2">
                    </g>
                    <g id="Symbol_-_Monochromatic_1_">
                        <g id="_x33__4_">
                        </g>
                    </g>
                </g>
             </svg>
		);
	}
}

export default ArbitrumLogo
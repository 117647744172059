

import React, {Component} from "react"

class Arrow extends Component {

	render() {
		return (
            <svg x="0px" y="0px"
	 width="70px" height="70" viewBox="0 0 579.083 579.083">
<g fill="white">
	<path d="M397,190.734L228.738,73.444c-6.599-5.632-11.996-3.147-11.996,5.528v49.068c0,8.672-7.099,15.77-15.77,15.77
				l-104.176,0.156H15.69v0.125C7.051,144.139,0.002,151.214,0,159.857l0.002,82.789c0,8.673,7.095,15.771,15.765,15.771
				l183.426-0.275h1.834c8.647,0.028,15.717,7.107,15.717,15.765v49.067c0,8.675,5.397,11.163,11.993,5.535l168.265-117.294
				C403.598,205.579,403.598,196.367,397,190.734z"/>
</g>
</svg>);
	}
}

export default Arrow
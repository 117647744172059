import React, {Component} from "react"

class DpiLogo extends Component {

	render() {
		return (
            <svg width="22px" height="22px" viewBox="0 0 22 22">
            <defs>
                <polygon id="path-1" points="0.0494352941 0.057545098 21.9441804 0.057545098 21.9441804 22.0286431 0.0494352941 22.0286431"></polygon>
            </defs>
            <g id="icons/crypto/dpi" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="random">
                    <g id="Clipped">
                        <g id="Path"></g>
                        <path d="M21.9441804,11.043051 C21.9441804,17.1102196 17.0429255,22.0286431 10.9968078,22.0286431 C4.9506902,22.0286431 0.0494352941,17.1102196 0.0494352941,11.043051 C0.0494352941,4.97596863 4.9506902,0.057545098 10.9968078,0.057545098 C17.0429255,0.057545098 21.9441804,4.97596863 21.9441804,11.0431373 L21.9441804,11.043051 Z" id="Path" fill="#8150E6" fillRule="nonzero" ></path>
                    </g>
                    <path d="M21.5350667,14.0276314 L13.7411137,6.3312549 L9.29987451,5.49905098 L6.70059608,15.3138118 L13.2591843,21.7931137 C17.2493804,20.9516784 20.4396392,17.9303451 21.5350667,14.0276314 Z" id="Path" fill="#3C176B" fillRule="nonzero"></path>
                    <path d="M10.0517569,14.4535686 L10.0517569,8.38036078 L11.5813176,8.38036078 C12.0954275,8.38036078 12.5676078,8.44592941 12.9977725,8.57680784 C13.4277647,8.70785882 13.8002118,8.91155294 14.1149412,9.18806275 C14.1581647,9.22593725 14.199749,9.26579608 14.2403843,9.30694902 C14.2456471,8.48371765 13.9485176,7.66341961 13.528102,6.92145882 C13.0592404,6.78827552 12.5741238,6.72099742 12.0867137,6.72155728 L8.37587451,6.72155728 L8.37587451,14.4459765 C8.93985098,14.4523608 9.49062745,14.4984314 10.0517569,14.4535686 Z" id="Path" fill="#6B42BD" fillRule="nonzero"></path>
                    <path d="M16.4758431,9.59536471 C16.2098588,8.9502902 15.855098,8.41443922 15.4118196,7.98746667 C14.9683686,7.56066667 14.4585725,7.2430902 13.8822588,7.03439216 C13.7655846,6.99252987 13.6474575,6.95483281 13.528102,6.92137255 C13.9485176,7.66333333 14.2456471,8.48363137 14.2403843,9.30686275 C14.4955843,9.56551373 14.6586431,10.5685412 14.8117804,10.9534118 L10.0517569,15.3139843 L10.0517569,14.4534824 C9.49062745,14.4983451 8.93985098,14.4522745 8.37596078,14.4458902 L8.37596078,16.9727843 L11.8872471,16.9727843 C12.4545882,16.9727843 13.0331451,16.868651 13.6229176,16.6599529 C14.2125176,16.4514275 14.7488863,16.1360941 15.2321961,15.7141255 C15.7154196,15.2921569 16.1101255,14.7587216 16.4159686,14.1135608 C16.721898,13.4685725 16.8748627,12.7046118 16.8748627,11.8217647 C16.8748627,10.9486667 16.7419137,10.2405255 16.4758431,9.59536471 L16.4758431,9.59536471 Z" id="Path" fill="#9B74EC" fillRule="nonzero"></path>
                    <path d="M9.62642353,13.6529412 C10.1495059,13.6529412 10.6439451,13.5875451 11.1093961,13.4565804 C11.5749333,13.3255294 11.9805961,13.1218353 12.3263843,12.8454118 C12.6721725,12.5689882 12.9448863,12.2101725 13.1443529,11.7686196 C13.3439059,11.3273255 13.4436392,10.7914745 13.4436392,10.1607216 C13.4436392,9.54006275 13.3548627,9.05727059 13.1775686,8.61097255 C13.000102,8.16484706 12.7541333,7.80344314 12.4394902,7.52693333 C12.1245882,7.25042353 11.7522275,7.04664314 11.3222353,6.91576471 C10.8920706,6.7848 10.4198902,6.71931765 9.90569412,6.71931765 L8.37621961,6.71931765 L8.37621961,13.6529412 L9.62642353,13.6529412 Z M6.70033725,5.06051765 L10.4110902,5.06051765 C11.0316627,5.06051765 11.630149,5.1649098 12.2067216,5.37334902 C12.782949,5.58213333 13.2927451,5.89962353 13.7361961,6.3265098 C14.1795608,6.75348235 14.5343216,7.28933333 14.8002196,7.93432157 C15.0662902,8.57948235 15.1992392,9.2877098 15.1992392,10.1607216 C15.1992392,11.0435686 15.0462745,11.8075294 14.7404314,12.4525176 C14.434502,13.0977647 14.0397961,13.6311137 13.5566588,14.0531686 C13.073349,14.4751373 12.5368941,14.7903843 11.9473804,14.9989098 C11.3575216,15.2076078 10.7789647,15.3118275 10.2116235,15.3118275 L6.70033725,15.3118275 L6.70033725,5.06051765 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
                </g>
            </g>
        </svg>
		);
	}
}

export default DpiLogo
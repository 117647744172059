import React, {Component} from "react"
import { Fragment } from "react";

import { connect } from "react-redux";

import Card from '../components/Card';
import { Modal, LargeModal } from "../components/Modal";
import ConnectModal from "../components/Modal"
import { Button, ButtonSmall } from '../components/Button';
import PendingTxModal from "../components/modals/PendingTxModal";
import AlertModal from "../components/modals/AlertModal";
import TxResultModal from "../components/modals/TxResultModal";
import DeployTxModal from "../components/modals/DeployTxModal";
import NewPoolModal from "../components/modals/NewPoolModal";
import PendingTxList from "../components/PendingTxList";
import ConnectPendingModal from "../components/modals/ConnectPendingModal";

import { updateVerifiedPoolInfo } from "../actions/verifiedPoolInfo"
import { updateOwnerPoolInfo } from "../actions/ownerPoolInfo"
import { updateUserDepositPoolInfo } from "../actions/userDepositPoolInfo"
import { updateDeployTxResult } from  "../actions/deployTxResult";
import { updateDeployInfo} from "../actions/deployInfo";
import { updateDepositAmount } from  "../actions/depositAmount";
import { updateWithdrawAmount } from  "../actions/withdrawAmount";
import { updateClaim } from "../actions/claim";
import { updateApprove } from "../actions/approve";
import { updateShare } from  "../actions/share";
import { updateNewAbout } from  "../actions/newAbout";
import { updateAlert } from "../actions/alert";

import LogoCard from "../components/logos/LogoCard";
import { precise, numberWithCommas, getHeaderValuesInUSD } from '../func/ancillaryFunctions';
import { getUserOwned, getUserDeposits } from "../func/contractInteractions";

class Dashboard extends Component {

	constructor(props) {
		super(props);

		this.state = {
			openTabIndex: 0,
			openVerifiedIndex: 0,
			hideLowBalance: false,
		}
	}
	componentDidMount = async () => {
		try{
			window.scrollTo(0,0);

			let currentTab = Number(sessionStorage.getItem('openTabIndex'));
			if(currentTab){
				this.setState({
					openTabIndex: currentTab
				});
			}

			let currentVerifiedTab = Number(sessionStorage.getItem('openVerifiedIndex'));
			if(currentVerifiedTab){
				this.setState({
					openVerifiedIndex: currentVerifiedTab
				});
			}

			if(this.props.deployInfo) await this.props.updateDeployInfo('');
			if(this.props.newAbout) await this.props.updateNewAbout('');
			if(this.props.depositAmount) await this.props.updateDepositAmount('');
			if(this.props.withdrawAmount) await this.props.updateWithdrawAmount('');
			if(this.props.approve) await this.props.updateApprove('');
			if(this.props.share) await this.props.updateShare("");
			if(this.props.claim)  await this.props.updateClaim('');
		}
		catch (error) {
			// Catch any errors for any of the above operations.
			alert(
				error,
			);
			console.error(error);
		}
	}

	getTxResultModal = () => {
		if(this.props.txResult){
			let modal = <Modal isOpen={true}><TxResultModal txDetails={this.props.txResult}/></Modal>;
			return modal;
		}
	}
	getPendingTxModal = () => {
		if(this.props.pendingTx){
			let modal = <Modal isOpen={true}><PendingTxModal txDetails={this.props.pendingTx}/></Modal>;
			//return modal;
		}
	}

	getAlertModal = () => {
		if(this.props.alert){
			let modal = <Modal isOpen={true}><AlertModal info={this.props.alert}/></Modal>;
			return modal;
		}
	}

	getDeployTxModal = () => {
		if(this.props.deployTxResult){
			let modal = <Modal isOpen={true}><DeployTxModal txDetails={this.props.deployTxResult}/></Modal>;
			return modal;
		}
	}

	getConnectModal = () => {
		if(this.props.activeAccount === "Pending"){
			let modal = <ConnectModal isOpen={true}><ConnectPendingModal/></ConnectModal>;
			return modal;
		}
	}

	getNewPoolModal = () => {
		if(this.props.deployInfo){
			let modal = <LargeModal isOpen={true}><NewPoolModal poolInfo={this.props.deployInfo}/></LargeModal>;
			return modal;
		}
	}
	deploy = async() => {
		await this.props.updateDeployInfo('');
		const activeAccount = this.props.activeAccount;
		this.props.updateDeployInfo({activeAccount: activeAccount});
	}

	displayDeployInfo = async(txInfo) => {
		this.props.updateDeployTxResult('');
		this.props.updateDeployTxResult(txInfo);
		await this.delay(5000);
		this.props.updateDeployTxResult('');
	}
	delay = (delayInms) => {
		return new Promise(resolve => {
		  setTimeout(() => {
			resolve(2);
		  }, delayInms);
		});
	}

	setSelectedToken = async(index) => {

		if(this.props.deployInfo) await this.props.updateDeployInfo('');
		if(this.props.newAbout) await this.props.updateNewAbout('');
		if(this.props.depositAmount) await this.props.updateDepositAmount('');
		if(this.props.withdrawAmount) await this.props.updateWithdrawAmount('');

		if(this.props.approve) await this.props.updateApprove('');
		if(this.props.share) await this.props.updateShare("");
		if(this.props.claim)  await this.props.updateClaim('');
		this.setState({
			openTabIndex: index,
		});
		sessionStorage.setItem('openTabIndex', index);
	}

	setSelectedVerifiedTab = async(index) => {
		this.setState({
			openVerifiedIndex: index,
		});
		sessionStorage.setItem('openVerifiedIndex', index);
	}

	createOptionButtons = () => {
		let buttonHolder = [];
		const buttonStrings = ['Verified Causes', 'Your Causes', 'Your Donations'];
		const infoStrings = ['team verified pools', 'view and update your causes', 'your donations'];
		let createDisabled;
		if(["Connect", "Pending"].includes(this.props.activeAccount)){
			createDisabled = true;
		}
		for(let i = 0; i < buttonStrings.length; i++){
			const name = buttonStrings[i];
			let isDisabled = false;
			if(i === this.state.openTabIndex){
				isDisabled = true;
			}
			buttonHolder.push(<div title={infoStrings[i]} key={i}><ButtonSmall text={name} disabled={isDisabled} callback={() => this.setSelectedToken(i)}/></div>)
		}
		buttonHolder.push(<div style={{marginLeft: "30px"}} key={4} title={createDisabled ? "connect wallet to create cause" : "create your own cause"} ><ButtonSmall text="Create Pool" disabled={createDisabled} callback={async() => await this.deploy(this.props.tokenMap, this.props.poolTrackerAddress)}/></div>);
		return buttonHolder;
	}

	createVerifiedButtons = () => {
		if(this.props.networkId !== 137) return;
		if(this.state.openTabIndex !== 0) return;
		let buttonHolder = [];
		const buttonStrings = ['General', 'Crypto for Charity'];
		const infoStrings = ['miscellaneous funds', 'Crypto for Charity cause funds'];
		for(let i = 0; i < buttonStrings.length; i++){
			const name = buttonStrings[i];
			let isDisabled = false;
			if(i === this.state.openVerifiedIndex){
				isDisabled = true;
			}
			buttonHolder.push(<div title={infoStrings[i]} key={i}><ButtonSmall text={name} disabled={isDisabled} callback={() => this.setSelectedVerifiedTab(i)}/></div>)
		}
		return buttonHolder;
	}

	getTabTitle = () => {
		let title;
		if(this.state.openTabIndex === 0) title = "Verified Pools";
		else if (this.state.openTabIndex === 1) title = "Your Causes";
		else if (this.state.openTabIndex === 2) title = "Your Donations";
		return (
			<div style={{marginTop: "100px", display:"flex", flexDirection: "wrap", alignItems:"center", justifyContent:"center"}}>
				<h2 style={{marginTop: "50px"}}> {title}</h2>
			</div>
		);
	}

	getVerifiedTabInfo = () => {
		if(this.props.networkId !== 137) return;
		if(this.state.openTabIndex !== 0) return;
		let info;
		if(this.state.openVerifiedIndex === 1){
			const info_1 = "The Crypto for Charity team is part of FreeWill, a technology company on a mission to empower both donors and nonprofits to do the most good for the people and causes they love.";
			const info_2 = "A Crypto for Charity cause fund supports a collection of nonprofits with a shared mission or area of focus. The distribution of your donation to a cause fund is an excellent option for those who want to donate more broadly to a cause."
			const width = this.props.isMobile ? "200px" : "300px"

			return (
				<div style={{marginTop: "25px", maxWidth: "600px", alignItems:"center", justifyContent:"center"}}>
					<img style={{width: width, border: "solid"}} src={require("../../images/c4c.jpg")} alt={"logo"}/>
					<p style={{alignItems:"center", marginTop: "25px", justifyContent:"center", marginRight:"0%"}} className="mr">{info_1}</p>
					<p style={{alignItems:"center", justifyContent:"center", marginRight:"0%"}} className="mr">{info_2}</p>
				</div>
			);
		}

		else if (this.state.openVerifiedIndex === 0){
			info = "Pools in this group consist of public goods, charities, and nonprofits.";
			return (
				<div style={{marginTop: "25px", maxWidth: "600px", alignItems:"center", justifyContent:"center"}}>
					<p style={{alignItems:"center", justifyContent:"center"}} className="mr">{info}</p>
				</div>
			);
		}
	}

	getTabInfo = () => {

		let info;
		if(this.state.openTabIndex === 0) info = "The recipients of verified pools are known and established entities";
		else if (this.state.openTabIndex === 1) info = "Causes for which you are the receiving address";
		else if (this.state.openTabIndex === 2) info = "Causes to which you have contributed donations";
		return (
			<div style={{marginTop: "25px", maxWidth: "300px", alignItems:"center", justifyContent:"center"}}>
				<p style={{marginRight: "0%"}} className="mr">{info}</p>
			</div>
		);
	}

	setHideLowBalances = () => {
		let orig = this.state.hideLowBalance;

		this.setState({
			hideLowBalance: (!orig)
		});
	}

	getHideLowTitle = () => {
		if(["Connect", "Pending"].includes(this.props.activeAccount)){
			return "Connect wallet"
		}
		return this.state.hideLowBalance ? "show all pools contributed to" : "hide inactive pools"
	}
	getHideLowButton = () => {
		if (this.state.openTabIndex === 2){
			return (
				<div title={this.getHideLowTitle()} style={{height: '50px', maxWidth: "1000px", borderRadius: "8px", marginLeft: "auto", marginRight: "auto"}}>
					<ButtonSmall text={this.state.hideLowBalance ? "Show All" : "Hide Zero/Low Balances"} disabled={["Connect", "Pending"].includes(this.props.activeAccount)} callback={() => this.setHideLowBalances()}/>
				</div>
			);
		}
		return (<div style={{ height: '50px' }} />);
	}

	createCardInfo = () => {
		const poolInfo = [this.props.verifiedPoolInfo, this.props.ownerPoolInfo, this.props.userDepositPoolInfo][this.state.openTabIndex];
		if(["Connect", "Connecting"].includes(this.props.activeAccount) &&
		   ((!this.props.ownerPoolInfo && this.state.openTabIndex === 1) || (!this.props.userDepositPoolInfo && this.state.openTabIndex === 2))){
			return(
			<div className="card__cardholder_slide" style={{display:"flex", flexDirection: "wrap", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:"auto"}}>
				<LogoCard/>
				<div style={{display:"flex", flexDirection: "column", alignItems:"left", justifyContent:"left"}}>

					<h1 style={{marginBottom: "5px", marginLeft: "20px"}} >JustCause</h1>
					<h2 style={{marginBottom: "5px", fontSize:17, marginLeft: "20px", marginRight: "auto"}} >Connect Wallet to view causes</h2>
				</div>
			</div>
			);
		}

		if(!["Connect", "Connecting"].includes(this.props.activeAccount)){
			if(this.state.openTabIndex === 1 && this.props.ownerPoolAddrs.length === 0){
				return (<div className="page-section--center card__delay" style={{ width: "100%"}}>
					<h2>{"Not Receiving Address for Any JustCause Pools"}</h2>
				   </div>);
			}
			else if(this.state.openTabIndex === 2 && this.props.userDepositPoolAddrs.length === 0){
				return (<div className="page-section--center card__delay" style={{ width: "100%"}}>
					<h2>{"Not Contributed to Any JustCause Pools"}</h2>
				   </div>);
			}
		}


		if(!this.props.tokenMap || Object.keys(poolInfo).length === 0){
			return (<div className="page-section--center" style={{paddingTop: "48px", width: "100%"}}>
						<div className="card__loading">
							<h2 className="card__letter" style={{ animationDelay: '0.1s' }}>L</h2>
							<h2 className="card__letter" style={{ animationDelay: '0.2s' }}>O</h2>
							<h2 className="card__letter" style={{ animationDelay: '0.3s' }}>A</h2>
							<h2 className="card__letter" style={{ animationDelay: '0.4s' }}>D</h2>
							<h2 className="card__letter" style={{ animationDelay: '0.5s' }}>I</h2>
							<h2 className="card__letter" style={{ animationDelay: '0.6s' }}>N</h2>
							<h2 className="card__letter" style={{ animationDelay: '0.7s' }}>G</h2>
						</div>
				    </div>);
		}

		let cardHolder = [];
		Object.entries(poolInfo).forEach(([key, value]) => {
			const {userBalance} = getHeaderValuesInUSD(value.acceptedTokenInfo, this.props.tokenMap);
			if(this.state.hideLowBalance && this.state.openTabIndex === 2){
				if(userBalance !== "<$0.01" && userBalance !== "$0.00"){
					cardHolder.push(
						<Card
							key={value.address}
							title={value.name}
							idx={cardHolder.length}
							receiver={value.receiver}
							address={value.address}
							acceptedTokenInfo={value.acceptedTokenInfo}
							about={value.about}
							picHash={value.picHash}
							isVerified={value.isVerified}
						/>
					);
				}
			}
			else if(this.state.openTabIndex === 0){
				const name = value.name;
				if(this.state.openVerifiedIndex === 1){
					if(name.endsWith("Cause Fund") || name === "Environment Conservation Fund" || name === "Healthcare & Research Fund"){
						cardHolder.push(
							<Card
								key={value.address}
								title={value.name}
								idx={cardHolder.length}
								receiver={value.receiver}
								address={value.address}
								acceptedTokenInfo={value.acceptedTokenInfo}
								about={value.about}
								picHash={value.picHash}
								isVerified={value.isVerified}
							/>
						);
					}
				}
				else if(this.state.openVerifiedIndex === 0){
					if(!name.endsWith("Cause Fund") && name !== "Healthcare & Research Fund" && name !== "Environment Conservation Fund" ){
						cardHolder.push(
							<Card
								key={value.address}
								title={value.name}
								idx={cardHolder.length}
								receiver={value.receiver}
								address={value.address}
								acceptedTokenInfo={value.acceptedTokenInfo}
								about={value.about}
								picHash={value.picHash}
								isVerified={value.isVerified}
							/>
						);
					}
				}
			}
			else{
				cardHolder.push(
					<Card
						key={value.address}
						title={value.name}
						idx={cardHolder.length}
						receiver={value.receiver}
						address={value.address}
						acceptedTokenInfo={value.acceptedTokenInfo}
						about={value.about}
						picHash={value.picHash}
						isVerified={value.isVerified}
					/>
				);
			}
		});
		return (
			<div className="card__cardholder_slide">
				{cardHolder}
			</div>
		);
	}

	render() {
		const optionButtons = this.createOptionButtons();
		const verifiedButtons = this.createVerifiedButtons();

		return (
			<Fragment>
				<article>
					<section  className="page-section page-section--center horizontal-padding bw0" style={{paddingBottom:"0px", minHeight: "200px"}}>
						<div style={{display:"flex", flexDirection: "column", alignItems:"center", justifyContent:"center"}}>
							<div style={{height: '50px', display:"flex", marginTop: "40px"}}>
								{optionButtons}
							</div>
							{this.getTabInfo()}
							<div style={{display:"flex"}}>
								{verifiedButtons}
							</div>
							{this.getVerifiedTabInfo()}
							{this.getHideLowButton()}
						</div>
					</section>
					<section className="page-section_no_vert_padding horizontal-padding bw0">
						{this.getConnectModal()}
						{this.getPendingTxModal()}
						{this.getTxResultModal()}
						{this.getDeployTxModal()}
						{this.getAlertModal()}
						{this.getNewPoolModal()}
						{this.createCardInfo()}
					</section>
				</article>
				<PendingTxList/>
			</Fragment>

		);
	}
}

const mapStateToProps = state => ({
	activeAccount: state.activeAccount,
	tokenMap: state.tokenMap,
	verifiedPoolAddrs: state.verifiedPoolAddrs,
	verifiedPoolInfo: state.verifiedPoolInfo,
	ownerPoolInfo: state.ownerPoolInfo,
	ownerPoolAddrs: state.ownerPoolAddrs,
	userDepositPoolInfo: state.userDepositPoolInfo,
	userDepositPoolAddrs: state.userDepositPoolAddrs,
	poolTrackerAddress: state.poolTrackerAddress,
	pendingTx: state.pendingTx,
	txResult: state.txResult,
	deployTxResult: state.deployTxResult,
	depositAmount: state.depositAmount,
	deployInfo: state.deployInfo,
	newAbout: state.newAbout,
	pendingTxList: state.pendingTxList,
	alert: state.alert,
	isMobile: state.isMobile,
	networkId: state.networkId,
	alert: state.alert,
})

const mapDispatchToProps = dispatch => ({
	updateVerifiedPoolInfo: (infoArray) => dispatch(updateVerifiedPoolInfo(infoArray)),
	updateUserDepositPoolInfo: (infoArray) => dispatch(updateUserDepositPoolInfo(infoArray)),
	updateOwnerPoolInfo: (infoArray) => dispatch(updateOwnerPoolInfo(infoArray)),
	updateDeployTxResult: (res) => dispatch(updateDeployTxResult(res)),
	updateDeployInfo: (res) => dispatch(updateDeployInfo(res)),
	updateDepositAmount: (amnt) => dispatch(updateDepositAmount(amnt)),
	updateWithdrawAmount: (amount) => dispatch(updateWithdrawAmount(amount)),
	updateClaim: (txInfo) => dispatch(updateClaim(txInfo)),
	updateApprove: (txInfo) => dispatch(updateApprove(txInfo)),
	updateShare: (share) => dispatch(updateShare(share)),
	updateNewAbout: (about) => dispatch(updateNewAbout(about)),
	updateAlert: (info) => dispatch(updateAlert(info)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

import React, {Component} from "react"

class ArbitrumBrand extends Component {

	render() {

		return (
            <svg width="450" height="63" viewBox="0 0 2500 637" fill="none">
                <g>
			<g>
				<path fill="#213147" d="M21.7,193.7v249.6c0,15.3,8.9,30.6,21.7,38.2l216.5,124.8c14,7.6,30.6,7.6,44.6,0l216.5-124.8      c14-7.6,21.7-22.9,21.7-38.2V193.7c0-15.3-8.9-30.6-21.7-38.2L304.4,30.7c-14-7.6-30.6-7.6-44.6,0L43.3,155.5      C29.3,163.1,21.7,178.4,21.7,193.7z"></path>
				<g>
					<g>
						<g>
							<path fill="#12AAFF" d="M329.9,366.9L299.3,451c-1.3,2.5-1.3,5.1,0,7.6l53.5,145.2l61.1-35.7L340,366.9         C338.8,361.8,332.4,361.8,329.9,366.9z"></path>
						</g>
						<g>
							<path fill="#12AAFF" d="M391,224.3c-1.3-5.1-7.6-5.1-10.2,0l-30.6,84.1c-1.3,2.5-1.3,5.1,0,7.6l86.6,238.2l61.1-35.7L391,224.3z         "></path>
						</g>
					</g>
				</g>
				<g>
					<path fill="#9DCCED" d="M281.5,39.6c1.3,0,2.5,0,3.8,1.3l234.3,135c2.5,1.3,3.8,3.8,3.8,7.6v270c0,2.5-1.3,6.4-3.8,7.6l-234.3,135       c-1.3,1.3-2.5,1.3-3.8,1.3c-1.3,0-2.5,0-3.8-1.3l-234.3-135c-2.5-1.3-3.8-3.8-3.8-7.6v-270c0-2.5,1.3-6.4,3.8-7.6l234.3-135       C278.9,39.6,280.2,39.6,281.5,39.6L281.5,39.6z M281.5,0.1c-8.9,0-16.6,2.5-24.2,6.4l-234.3,135c-15.3,8.9-24.2,24.2-24.2,42       v270c0,16.6,8.9,33.1,24.2,42l234.3,135c7.6,3.8,15.3,6.4,24.2,6.4c8.9,0,16.6-2.5,24.2-6.4l234.3-135       c15.3-8.9,24.2-24.2,24.2-42v-270c0-16.6-8.9-33.1-24.2-42L306.9,6.5c-7.6-3.8-15.3-6.4-24.2-6.4l0,0H281.5z"></path>
				</g>
				<polygon fill="#213147" points="127.4,554.1 149,495.5 192.3,531.2 151.6,569.4     "></polygon>
				<g>
					<path fill="#FFFFFF" d="M262.4,164.4h-59.9c-3.8,0-8.9,2.5-10.2,6.4L65,519.7l61.1,35.7L266.2,172c1.3-3.8-1.3-7.6-5.1-7.6H262.4z       "></path>
					<path fill="#FFFFFF" d="M365.5,164.4h-59.9c-3.8,0-8.9,2.5-10.2,6.4L150.3,568.1l61.1,35.7l157.9-433c1.3-3.8-1.3-7.6-5.1-7.6       L365.5,164.4z"></path>
				</g>
			</g>
			<g>
				<g>
                    <path fill="#213147" d="M816.4,237c6.4,0,11.5,1.3,15.3,3.8s7.6,7.6,11.5,14l77.7,140.1c1.3,1.3,1.3,2.5,0,3.8       c0,1.3-1.3,1.3-3.8,1.3h-29.3c-2.5,0-3.8-1.3-5.1-2.5L866,366.9H756.5l-16.6,30.6c-1.3,2.5-2.5,2.5-5.1,2.5h-30.6       c-2.5,0-3.8,0-3.8-1.3s0-2.5,0-3.8l76.4-140.1c3.8-7.6,7.6-11.5,11.5-14s7.6-3.8,12.7-3.8h14H816.4z M770.5,340.2h80.2       l-36.9-68.8c-1.3-1.3-1.3-1.3-2.5-1.3H810c-1.3,0-2.5,0-2.5,1.3L770.5,340.2z"></path>
                    <path fill="#213147" d="M1090.2,237c17.8,0,30.6,3.8,38.2,12.7c7.6,7.6,11.5,20.4,11.5,35.7v12.7c0,14-2.5,24.2-7.6,31.8       s-14,12.7-26.7,15.3l39.5,49.7c1.3,1.3,1.3,2.5,1.3,3.8s-1.3,2.5-3.8,2.5h-30.6c-2.5,0-3.8,0-3.8-1.3c-1.3,0-1.3-1.3-2.5-2.5       l-36.9-48.4h-75.1v47.1c0,3.8-1.3,5.1-5.1,5.1h-25.5c-3.8,0-5.1-1.3-5.1-5.1V244.6c0-5.1,2.5-7.6,7.6-7.6h126.1l0,0H1090.2z        M993.4,321h89.1c7.6,0,14-1.3,16.6-5.1c3.8-3.8,5.1-8.9,5.1-15.3v-10.2c0-7.6-1.3-12.7-5.1-15.3c-3.8-3.8-8.9-5.1-16.6-5.1       h-86.6c-2.5,0-2.5,1.3-2.5,2.5V321z"></path>
                    <path fill="#213147" d="M1336,237c16.6,0,28,3.8,34.4,10.2c6.4,6.4,10.2,16.6,10.2,29.3v11.5c0,12.7-5.1,22.9-15.3,29.3       c10.2,6.4,16.6,16.6,16.6,30.6v14c0,6.4-1.3,11.5-2.5,16.6s-3.8,8.9-6.4,12.7c-3.8,3.8-7.6,6.4-12.7,7.6       c-5.1,1.3-11.5,2.5-19.1,2.5h-138.8c-5.1,0-7.6-2.5-7.6-7.6v-149c0-5.1,2.5-7.6,7.6-7.6h135l0,0H1336z M1230.3,304.5h95.5       c7.6,0,12.7-1.3,15.3-3.8s3.8-5.1,3.8-10.2v-7.6c0-5.1-1.3-8.9-3.8-10.2c-2.5-2.5-7.6-3.8-12.7-3.8h-95.5       c-2.5,0-2.5,1.3-2.5,2.5V304.5L1230.3,304.5z M1230.3,330v34.4c0,2.5,1.3,2.5,2.5,2.5h95.5c6.4,0,10.2-1.3,12.7-3.8       s3.8-6.4,3.8-10.2V344c0-5.1-1.3-8.9-3.8-11.5s-7.6-3.8-16.6-3.8H1229l0,0L1230.3,330z"></path>
                    <path fill="#213147" d="M1468.4,237c3.8,0,5.1,1.3,5.1,5.1v152.8c0,3.8-1.3,5.1-5.1,5.1h-25.5c-3.8,0-5.1-1.3-5.1-5.1V242.1       c0-3.8,1.3-5.1,5.1-5.1H1468.4z"></path>
                    <path fill="#213147" d="M1714.2,237c3.8,0,5.1,1.3,5.1,5.1V265c0,3.8-1.3,5.1-5.1,5.1h-73.9v124.8c0,3.8-1.3,5.1-5.1,5.1h-25.5       c-3.8,0-5.1-1.3-5.1-5.1V270.1h-73.9c-3.8,0-5.1-1.3-5.1-5.1v-22.9c0-3.8,1.3-5.1,5.1-5.1h182.1H1714.2z"></path>
                    <path fill="#213147" d="M1898.9,237c17.8,0,30.6,3.8,38.2,12.7c7.6,7.6,11.5,20.4,11.5,35.7v12.7c0,14-2.5,24.2-7.6,31.8       s-14,12.7-26.7,15.3l39.5,49.7c1.3,1.3,1.3,2.5,1.3,3.8s-1.3,2.5-3.8,2.5h-30.6c-2.5,0-3.8,0-3.8-1.3c-1.3,0-1.3-1.3-2.5-2.5       l-36.9-48.4h-75.1v47.1c0,3.8-1.3,5.1-5.1,5.1h-25.5c-3.8,0-5.1-1.3-5.1-5.1V244.6c0-5.1,2.5-7.6,7.6-7.6h126.1l0,0H1898.9z        M1802.1,321h89.1c7.6,0,14-1.3,16.6-5.1c3.8-3.8,5.1-8.9,5.1-15.3v-10.2c0-7.6-1.3-12.7-5.1-15.3c-3.8-3.8-8.9-5.1-16.6-5.1       h-86.6c-2.5,0-2.5,1.3-2.5,2.5V321L1802.1,321z"></path>
                    <path fill="#213147" d="M2030.1,237c3.8,0,5.1,1.3,5.1,5.1v93c0,6.4,0,11.5,1.3,15.3s2.5,7.6,5.1,10.2s5.1,3.8,8.9,5.1       c3.8,1.3,8.9,1.3,15.3,1.3h65c6.4,0,11.5,0,15.3-1.3c3.8-1.3,7.6-2.5,8.9-5.1c2.5-2.5,3.8-6.4,5.1-10.2       c1.3-3.8,1.3-8.9,1.3-15.3v-93c0-3.8,1.3-5.1,5.1-5.1h25.5c3.8,0,5.1,1.3,5.1,5.1v96.8c0,11.5-1.3,20.4-3.8,28       c-2.5,7.6-6.4,14-11.5,19.1s-11.5,8.9-20.4,10.2c-8.9,2.5-17.8,3.8-30.6,3.8h-67.5c-11.5,0-21.7-1.3-30.6-3.8       c-7.6-2.5-15.3-6.4-20.4-10.2c-5.1-5.1-8.9-11.5-11.5-19.1c-2.5-7.6-3.8-17.8-3.8-28v-96.8c0-3.8,1.3-5.1,5.1-5.1h25.5H2030.1z       "></path>
                    <path fill="#213147" d="M2297.5,237c3.8,0,6.4,0,8.9,1.3c2.5,0,5.1,1.3,6.4,2.5c1.3,1.3,3.8,2.5,5.1,5.1c1.3,2.5,2.5,5.1,5.1,8.9       l48.4,112.1c1.3,1.3,1.3,2.5,3.8,2.5h2.5c1.3,0,2.5-1.3,3.8-2.5l48.4-112.1c1.3-3.8,2.5-6.4,5.1-8.9c1.3-2.5,2.5-3.8,5.1-5.1       c1.3-1.3,3.8-2.5,6.4-2.5s5.1-1.3,8.9-1.3h25.5c7.6,0,14,1.3,16.6,5.1c2.5,3.8,3.8,10.2,3.8,19.1v133.7c0,3.8-1.3,5.1-5.1,5.1       H2472c-3.8,0-5.1-1.3-5.1-5.1V272.7c0-1.3-1.3-2.5-1.3-2.5h-2.5c-1.3,0-2.5,1.3-2.5,1.3l-47.1,104.4c-1.3,3.8-3.8,7.6-6.4,10.2       s-3.8,5.1-6.4,7.6c-2.5,1.3-5.1,3.8-7.6,3.8c-2.5,1.3-6.4,1.3-10.2,1.3h-11.5c-3.8,0-7.6,0-10.2-1.3s-5.1-2.5-7.6-3.8       s-3.8-3.8-6.4-7.6c-2.5-2.5-3.8-6.4-6.4-10.2l-47.1-104.4c0-1.3-1.3-1.3-2.5-1.3h-2.5c-1.3,0-1.3,1.3-1.3,2.5v122.3       c0,3.8-1.3,5.1-5.1,5.1H2258c-3.8,0-5.1-1.3-5.1-5.1V261.2c0-8.9,1.3-15.3,3.8-19.1c2.5-3.8,8.9-5.1,16.6-5.1h25.5H2297.5z"></path>
                </g>
			</g>
		</g>
            </svg>
		);
	}
}

export default ArbitrumBrand

import React, {Component} from "react"

class UsdcLogoMd extends Component {

	render() {
		return (
      <svg width="66px" height="66px" viewBox="0 0 22 22">
          <title>icons/cypto/usdc</title>
          <g id="icons/cypto/usdc" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="usdc-logo" fillRule="nonzero">
                  <path d="M11,22 C17.09587,22 22,17.09587 22,11 C22,4.90413 17.09587,0 11,0 C4.90413,0 0,4.90413 0,11 C0,17.09587 4.90413,22 11,22 Z" id="Path" fill="#2775CA"></path>
                  <path d="M14.025,12.74163 C14.025,11.1375 13.0625,10.5875 11.1375,10.35837 C9.7625,10.175 9.4875,9.80837 9.4875,9.16663 C9.4875,8.52489 9.94587,8.1125 10.8625,8.1125 C11.6875,8.1125 12.14587,8.3875 12.375,9.075 C12.42087,9.2125 12.55837,9.30413 12.69587,9.30413 L13.42913,9.30413 C13.6125,9.30413 13.75,9.16663 13.75,8.98337 L13.75,8.9375 C13.56663,7.92913 12.74163,7.15 11.6875,7.05837 L11.6875,5.95837 C11.6875,5.775 11.55,5.6375 11.32087,5.59163 L10.63337,5.59163 C10.45,5.59163 10.3125,5.72913 10.26663,5.95837 L10.26663,7.0125 C8.89163,7.19587 8.02087,8.1125 8.02087,9.25837 C8.02087,10.77087 8.9375,11.36663 10.8625,11.59587 C12.14587,11.825 12.55837,12.1 12.55837,12.83337 C12.55837,13.56674 11.91663,14.07087 11.04587,14.07087 C9.85413,14.07087 9.44163,13.56663 9.30413,12.87913 C9.25837,12.69587 9.12087,12.60413 8.98337,12.60413 L8.20413,12.60413 C8.02087,12.60413 7.88337,12.74163 7.88337,12.925 L7.88337,12.97087 C8.06663,14.11663 8.8,14.94163 10.3125,15.17087 L10.3125,16.27087 C10.3125,16.45413 10.45,16.59163 10.67913,16.6375 L11.36663,16.6375 C11.55,16.6375 11.6875,16.5 11.73337,16.27087 L11.73337,15.17087 C13.10837,14.94163 14.025,13.97913 14.025,12.74163 L14.025,12.74163 Z" id="Path" fill="#FFFFFF"></path>
                  <path d="M8.6625,17.55413 C5.0875,16.27087 3.25413,12.28337 4.58337,8.75413 C5.27087,6.82913 6.78337,5.3625 8.6625,4.675 C8.84587,4.58337 8.9375,4.44587 8.9375,4.21663 L8.9375,3.575 C8.9375,3.39163 8.84587,3.25413 8.6625,3.20837 C8.61663,3.20837 8.525,3.20837 8.47913,3.25413 C4.125,4.62913 1.74163,9.25837 3.11663,13.6125 C3.94163,16.17913 5.9125,18.15 8.47913,18.975 C8.6625,19.06663 8.84587,18.975 8.89163,18.79163 C8.9375,18.74587 8.9375,18.7 8.9375,18.60837 L8.9375,17.96663 C8.9375,17.82913 8.8,17.64587 8.6625,17.55413 Z M13.52087,3.25413 C13.3375,3.1625 13.15413,3.25413 13.10837,3.4375 C13.0625,3.48337 13.0625,3.52913 13.0625,3.62087 L13.0625,4.2625 C13.0625,4.44587 13.2,4.62913 13.3375,4.72087 C16.9125,6.00413 18.74587,9.99163 17.41663,13.52087 C16.72913,15.44587 15.21663,16.9125 13.3375,17.6 C13.15413,17.69163 13.0625,17.82913 13.0625,18.05837 L13.0625,18.7 C13.0625,18.88337 13.15413,19.02087 13.3375,19.06663 C13.38337,19.06663 13.475,19.06663 13.52087,19.02087 C17.875,17.64587 20.25837,13.01663 18.88337,8.6625 C18.05837,6.05 16.04163,4.07913 13.52087,3.25413 Z" id="Shape" fill="#FFFFFF"></path>
              </g>
          </g>
      </svg>
    );
  }
}
export default UsdcLogoMd

import React, {Component} from "react"

class TetherLogoMd extends Component {
	render() {
		return (
            <svg width="66px" height="66px" viewBox="0 0 22 22">
                <g id="icons/cypto/tether" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="tether-logo" transform="translate(0.000000, 2.000000)">
                        <path d="M4.02147059,0.0938235294 L0.0168235294,8.50558824 C-0.0144555839,8.56980815 -0.000293918628,8.64691055 0.0517647059,8.69582353 L10.8673529,19.0597647 C10.9311856,19.1209803 11.0319321,19.1209803 11.0957647,19.0597647 L21.9113529,8.69647059 C21.9634116,8.64755761 21.9775732,8.57045521 21.9462941,8.50623529 L17.9416471,0.0944705882 C17.9152529,0.0367730892 17.8575655,-1.25939755e-05 17.7941176,-1.25939755e-05 L4.17029412,-1.25939755e-05 C4.10652954,-0.000793298373 4.04824885,0.0359488741 4.02147059,0.0938235294 L4.02147059,0.0938235294 Z" id="Path" fill="#50AF95"></path>
                        <path d="M12.3711176,9.36941176 L12.3711176,9.36941176 C12.2934706,9.37523529 11.8922941,9.39917647 10.9974118,9.39917647 C10.2856471,9.39917647 9.78029412,9.37782353 9.603,9.36941176 L9.603,9.36941176 C6.85235294,9.24841176 4.79923529,8.76958824 4.79923529,8.19629412 C4.79923529,7.623 6.85235294,7.14482353 9.603,7.02188235 L9.603,8.89252941 C9.78288235,8.90547059 10.2979412,8.93588235 11.0097059,8.93588235 C11.8638235,8.93588235 12.2915294,8.90029412 12.3685294,8.89317647 L12.3685294,7.02317647 C15.1133529,7.14547059 17.1619412,7.62429412 17.1619412,8.19629412 C17.1619412,8.76829412 15.114,9.24711765 12.3685294,9.36876471 L12.3685294,9.36876471 L12.3711176,9.36941176 Z M12.3711176,6.82970588 L12.3711176,5.15576471 L16.2017059,5.15576471 L16.2017059,2.60311765 L5.77241176,2.60311765 L5.77241176,5.15576471 L9.60235294,5.15576471 L9.60235294,6.82905882 C6.48935294,6.97205882 4.14829412,7.58870588 4.14829412,8.32764706 C4.14829412,9.06658824 6.48935294,9.68258824 9.60235294,9.82623529 L9.60235294,15.1903529 L12.3704706,15.1903529 L12.3704706,9.82429412 C15.4763529,9.68129412 17.8135294,9.06529412 17.8135294,8.327 C17.8135294,7.58870588 15.4782941,6.97270588 12.3704706,6.82905882 L12.3704706,6.82905882 L12.3711176,6.82970588 Z" id="Shape" fill="#FFFFFF"></path>
                    </g>
                </g>
            </svg>
        );
    }
}
export default TetherLogoMd

import React, {Component} from "react"

class WbtcLogo extends Component {
	render() {
		return (
			<svg width="22px" height="22px" viewBox="0 0 22 22">
				<g id="icons/cypto/wbtc" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="wbtc-logo" fillRule="nonzero">
							<path d="M17.818,4.586 L17.218,5.186 C20.1855333,8.43120891 20.1855333,13.4047911 17.218,16.65 L17.818,17.25 C21.1195654,13.6688375 21.1195654,8.15316246 17.818,4.572 L17.818,4.586 Z" id="Shape" fill="#5A5564"></path>
							<path d="M5.2,4.638 C8.44520891,1.6704667 13.4187911,1.6704667 16.664,4.638 L17.264,4.038 C13.6828375,0.736434649 8.16716246,0.736434649 4.586,4.038 L5.2,4.638 Z" id="Shape-2" fill="#5A5564"></path>
							<path d="M4.638,16.656 C1.67412268,13.4116733 1.67412268,8.44232665 4.638,5.198 L4.038,4.598 C0.736434649,8.17916246 0.736434649,13.6948375 4.038,17.276 L4.638,16.656 Z" id="Shape-3" fill="#5A5564"></path>
							<path d="M16.656,17.21 C13.4107911,20.1775333 8.43720891,20.1775333 5.192,17.21 L4.592,17.81 C8.17316246,21.1115654 13.6888375,21.1115654 17.27,17.81 L16.656,17.21 Z" id="Shape-4" fill="#5A5564"></path>
							<path d="M14.714,8.924 C14.594,7.672 13.514,7.252 12.148,7.124 L12.148,5.4 L11.092,5.4 L11.092,7.092 C10.814,7.092 10.53,7.092 10.248,7.092 L10.248,5.4 L9.2,5.4 L9.2,7.136 L7.058,7.136 L7.058,8.266 C7.058,8.266 7.838,8.252 7.826,8.266 C8.11832213,8.23384157 8.38359684,8.43898735 8.426,8.73 L8.426,13.482 C8.41992918,13.5817578 8.37376035,13.6748169 8.298,13.74 C8.22365,13.8064365 8.12541327,13.8396636 8.026,13.832 C8.04,13.844 7.258,13.832 7.258,13.832 L7.058,15.094 L9.18,15.094 L9.18,16.858 L10.236,16.858 L10.236,15.12 L11.08,15.12 L11.08,16.85 L12.138,16.85 L12.138,15.106 C13.922,14.998 15.166,14.558 15.322,12.888 C15.448,11.544 14.816,10.944 13.806,10.702 C14.42,10.4 14.8,9.84 14.714,8.924 Z M13.234,12.68 C13.234,13.992 10.986,13.842 10.27,13.842 L10.27,11.514 C10.986,11.516 13.234,11.31 13.234,12.68 Z M12.744,9.4 C12.744,10.6 10.868,10.454 10.272,10.454 L10.272,8.338 C10.868,8.338 12.744,8.15 12.744,9.4 Z" id="Shape-5" fill="#F09242"></path>
							<path d="M10.924,21.8520002 C4.89025766,21.8508954 -0.000367988337,16.9590759 2.03425692e-07,10.9253335 C0.000368395189,4.89159103 4.89159103,0.000368395189 10.9253335,2.03425692e-07 C16.9590759,-0.000367988337 21.8508954,4.89025766 21.8520002,10.924 C21.8525306,13.8224484 20.7013615,16.6023363 18.6518489,18.6518489 C16.6023363,20.7013615 13.8224484,21.8525306 10.924,21.8520002 Z M10.924,0.851996816 C5.36516144,0.856417476 0.861793364,5.36515642 0.864000778,10.9239963 C0.866209817,16.4828362 5.37315863,20.9879968 10.9319989,20.9879968 C16.4908393,20.9879968 20.9977897,16.4828399 21.0000008,10.924 C21.0010625,8.25172071 19.9397004,5.6886443 18.0497384,3.79943252 C16.1597763,1.91022074 13.5962787,0.849876404 10.924,0.851996816 Z" id="Shape-6" fill="#282138"></path>
						</g>
					</g>
			</svg>
		);
	}
}

export default WbtcLogo


import React, {Component} from "react"

class MaticLogo extends Component {

	render() {
		return (
            <svg width="22px" height="20px" viewBox="0 0 22 20">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="icons/crypto/matic" transform="translate(0.000000, -1.000000)" fill="#8247E5" fillRule="nonzero">
                        <g id="polygon-matic-logo" transform="translate(0.000000, 1.000000)">
                            <path d="M16.6089744,6.1025641 C16.2141026,5.87692308 15.7064103,5.87692308 15.2551282,6.1025641 L12.0961538,7.96410256 L9.9525641,9.14871795 L6.85,11.0102564 C6.45512821,11.2358974 5.9474359,11.2358974 5.49615385,11.0102564 L3.07051282,9.54358974 C2.67564103,9.31794872 2.39358974,8.86666667 2.39358974,8.35897436 L2.39358974,5.53846154 C2.39358974,5.08717949 2.61923077,4.63589744 3.07051282,4.35384615 L5.49615385,2.94358974 C5.89102564,2.71794872 6.39871795,2.71794872 6.85,2.94358974 L9.27564103,4.41025641 C9.67051282,4.63589744 9.9525641,5.08717949 9.9525641,5.59487179 L9.9525641,7.45641026 L12.0961538,6.21538462 L12.0961538,4.2974359 C12.0961538,3.84615385 11.8705128,3.39487179 11.4192308,3.11282051 L6.90641026,0.461538462 C6.51153846,0.235897436 6.00384615,0.235897436 5.5525641,0.461538462 L0.926923077,3.16923077 C0.475641026,3.39487179 0.25,3.84615385 0.25,4.2974359 L0.25,9.6 C0.25,10.0512821 0.475641026,10.5025641 0.926923077,10.7846154 L5.49615385,13.4358974 C5.89102564,13.6615385 6.39871795,13.6615385 6.85,13.4358974 L9.9525641,11.6307692 L12.0961538,10.3897436 L15.1987179,8.58461538 C15.5935897,8.35897436 16.1012821,8.35897436 16.5525641,8.58461538 L18.9782051,9.99487179 C19.3730769,10.2205128 19.6551282,10.6717949 19.6551282,11.1794872 L19.6551282,14 C19.6551282,14.4512821 19.4294872,14.9025641 18.9782051,15.1846154 L16.6089744,16.5948718 C16.2141026,16.8205128 15.7064103,16.8205128 15.2551282,16.5948718 L12.8294872,15.1846154 C12.4346154,14.9589744 12.1525641,14.5076923 12.1525641,14 L12.1525641,12.1948718 L10.0089744,13.4358974 L10.0089744,15.2974359 C10.0089744,15.7487179 10.2346154,16.2 10.6858974,16.4820513 L15.2551282,19.1333333 C15.65,19.3589744 16.1576923,19.3589744 16.6089744,19.1333333 L21.1782051,16.4820513 C21.5730769,16.2564103 21.8551282,15.8051282 21.8551282,15.2974359 L21.8551282,9.93846154 C21.8551282,9.48717949 21.6294872,9.03589744 21.1782051,8.75384615 L16.6089744,6.1025641 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </svg>
        );
	}
}

export default MaticLogo



import React, {Component} from "react"

class WEthLogoMd extends Component {

	render() {
		return (
            <svg width="66px" height="66px" viewBox="0 0 22 22">
                <g id="icons/crypto/weth" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="random" fillRule="nonzero">
                        <path d="M6.05517647,21.8705882 C11.6198824,21.8705882 11.6198824,21.7877647 12.5632941,21.5082353 C13.7952941,21.142 9.08211765,17.8788824 6.05517647,17.8788824 C3.02823529,17.8788824 0.574588235,18.7718235 0.574588235,19.8744118 C0.574588235,20.977 3.02823529,21.8705882 6.05517647,21.8705882 Z" id="Path" fill="#000000"></path>
                        <path d="M18.6902941,4.89758824 L18.9458824,5.17323529 L18.722,5.47541176 C11.4658824,15.2874118 7.28458824,19.7301176 5.511,19.1341765 C4.43041176,18.7711765 3.34723529,17.6795882 2.26405882,16.0949412 C1.88353942,15.5354337 1.52858663,14.9589593 1.20029412,14.3672941 C1.0264121,14.0575644 0.861345791,13.7429675 0.705294118,13.4238824 C0.605730542,13.2290603 0.53386007,13.0212893 0.491764706,12.8065882 L0.486588235,12.7813529 C0.476721426,12.7267975 0.464850651,12.6726236 0.451,12.6189412 C-0.567745681,8.78129382 0.806497557,4.70637232 3.94129389,2.2694825 C7.07609022,-0.16740732 11.3640723,-0.494117602 14.8318824,1.43970588 L14.8694118,1.463 C16.0664706,2.29641176 17.3392353,3.44105882 18.6902941,4.89758824 L18.6902941,4.89758824 Z M14.3698824,2.22070588 C11.219191,0.476091423 7.33130372,0.780729598 4.49079702,2.99478865 C1.65029032,5.2088477 0.405742178,8.90473348 1.32841176,12.386 C1.34782353,12.4604118 1.35688235,12.5024706 1.37758824,12.6092353 L1.38211765,12.6312353 C1.40929412,12.7716471 1.45717647,12.9075294 1.52447059,13.0343529 C1.61117647,13.2161765 1.78523529,13.5539412 1.99294118,13.926 C2.30770903,14.4932525 2.64797846,15.0459744 3.01270588,15.5824706 C3.99170588,17.0150588 4.96229412,17.9927647 5.80023529,18.2742353 C6.91705882,18.6495294 11.1222941,14.1977647 17.7675882,5.24052941 C16.5614706,3.96647059 15.4284706,2.96029412 14.3698824,2.22070588 L14.3698824,2.22070588 Z" id="Shape" fill="#000000"></path>
                        <path d="M14.3692353,2.22070588 C11.2185825,0.476509811 7.3310047,0.781359408 4.49073775,2.99534143 C1.6504708,5.20932346 0.405970983,8.90491745 1.32841176,12.386 C1.34782353,12.4604118 1.35688235,12.5024706 1.37758824,12.6092353 L1.38211765,12.6312353 C1.40929412,12.7716471 1.45717647,12.9075294 1.52447059,13.0337059 C1.61117647,13.2161765 1.78523529,13.5532941 1.99294118,13.926 C2.30769453,14.493261 2.64796436,15.0459836 3.01270588,15.5824706 C3.99170588,17.0144118 4.96229412,17.9927647 5.80023529,18.2742353 C6.91705882,18.6495294 11.1222941,14.1977647 17.7675882,5.24052941 C16.5608235,3.96647059 15.4284706,2.96029412 14.3692353,2.22070588 Z" id="Path" fill="#EC1C79"></path>
                        <path d="M11.825,21.78 C8.27282572,21.8087334 4.97811728,19.9301483 3.19368381,16.8585746 C1.40925033,13.7870009 1.40925033,9.99435203 3.19368381,6.92277834 C4.97811728,3.85120466 8.27282572,1.97261958 11.825,2.00135294 C17.2555097,2.04528006 21.6346528,6.45998915 21.6346528,11.8906765 C21.6346528,17.3213638 17.2555097,21.7360729 11.825,21.78 Z M11.825,20.8734706 C16.7853455,20.8734706 20.8065,16.8523161 20.8065,11.8919706 C20.8065,6.93162511 16.7853455,2.91047059 11.825,2.91047059 C6.8648332,2.91047059 2.84382353,6.93148026 2.84382353,11.8916471 C2.84382353,16.8518139 6.8648332,20.8728235 11.825,20.8728235 L11.825,20.8734706 Z" id="Shape" fill="#000000"></path>
                        <path d="M11.8256471,20.8728235 C16.7863499,20.8728235 20.8077941,16.8513793 20.8077941,11.8906765 C20.8077941,6.92997363 16.7863499,2.90852941 11.8256471,2.90852941 C6.86494422,2.90852941 2.8435,6.92997363 2.8435,11.8906765 C2.8435,16.8513793 6.86494422,20.8728235 11.8256471,20.8728235 L11.8256471,20.8728235 Z" id="Path" fill="#FFFFFF"></path>
                        <path d="M5.10658824,13.7305882 L3.91729412,10.1491176 L4.95905882,10.1491176 L5.57894118,12.2067647 L6.26482353,10.1387647 L7.11376471,10.1387647 L7.79964706,12.2067647 L8.41952941,10.1491176 L9.44058824,10.1491176 L8.25129412,13.7305882 L7.39264706,13.7305882 L6.67635294,11.6832941 L5.96458824,13.7305882 L5.10658824,13.7305882 L5.10658824,13.7305882 Z M9.83141176,13.7053529 L9.83141176,10.1491176 L12.6914118,10.1491176 L12.6914118,10.9870588 L10.8071765,10.9870588 L10.8071765,11.5260588 L12.5141176,11.5260588 L12.5141176,12.3031765 L10.8071765,12.3031765 L10.8071765,12.8674118 L12.7172941,12.8674118 L12.7172941,13.7053529 L9.83141176,13.7053529 L9.83141176,13.7053529 Z M14.0890588,13.7053529 L14.0890588,11.0129412 L13.0220588,11.0129412 L13.0220588,10.1491176 L16.1415294,10.1491176 L16.1415294,11.0129412 L15.0745294,11.0129412 L15.0745294,13.7053529 L14.0890588,13.7053529 L14.0890588,13.7053529 Z M16.5737647,13.7053529 L16.5737647,10.1491176 L17.5592353,10.1491176 L17.5592353,11.4801176 L18.8242353,11.4801176 L18.8242353,10.1491176 L19.8097059,10.1491176 L19.8097059,13.7053529 L18.8242353,13.7053529 L18.8242353,12.3542941 L17.5592353,12.3542941 L17.5592353,13.7053529 L16.5737647,13.7053529 Z" id="Shape" fill="#000000"></path>
                        <path d="M0.710470588,9.07952941 C0.459424696,8.95409579 0.357595823,8.64889885 0.483029432,8.39785295 C0.608463041,8.14680705 0.913659979,8.04497817 1.16470588,8.17041176 L2.61670588,8.89641176 C2.7882787,8.97219412 2.90421485,9.13625825 2.91835862,9.32328807 C2.93250239,9.51031788 2.84255771,9.68995136 2.68433274,9.79067403 C2.52610777,9.89139671 2.32528843,9.89685749 2.16182353,9.80488235 L0.710470588,9.07952941 L0.710470588,9.07952941 Z M0.543529412,11.4859412 C0.378703871,11.4098856 0.266973938,11.2516741 0.250427198,11.0709032 C0.233880458,10.8901323 0.315030756,10.7142656 0.463309557,10.6095502 C0.611588359,10.5048349 0.804468585,10.4871797 0.969294118,10.5632353 L2.42129412,11.2335882 C2.59552732,11.3037333 2.71691744,11.4641955 2.73702156,11.6509396 C2.75712567,11.8376837 2.67267069,12.0203064 2.5173599,12.1259288 C2.36204911,12.2315511 2.1611682,12.2429772 1.99488235,12.1556471 L0.543529412,11.4865882 L0.543529412,11.4859412 Z" id="Shape" fill="#000000"></path>
                    </g>
                </g>
            </svg>
		);
	}
}

export default WEthLogoMd
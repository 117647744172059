import React, {Component} from "react"

class AaveLogoMd extends Component {

	render() {
		return (
            <svg width="66px" height="66px" viewBox="0 0 22 22">
                <defs>
                    <linearGradient x1="87.2792784%" y1="18.632268%" x2="12.8792784%" y2="81.232268%" id="linearGradient-1">
                        <stop stopColor="#B6509E" offset="0%"></stop>
                        <stop stopColor="#2EBAC6" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g id="icons/crypto/aave" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="aave-logo" fillRule="nonzero">
                        <circle id="Oval" fill="url(#linearGradient-1)" cx="11" cy="11" r="11"></circle>
                        <path d="M15.8139175,15.3829897 L12.0943299,6.39020619 C11.8845361,5.92525773 11.5726804,5.69845361 11.1615979,5.69845361 L10.832732,5.69845361 C10.4216495,5.69845361 10.1097938,5.92525773 9.9,6.39020619 L8.28118557,10.3082474 L7.0564433,10.3082474 C6.69072165,10.3110825 6.39304124,10.6059278 6.39020619,10.9744845 L6.39020619,10.9829897 C6.39304124,11.3487113 6.69072165,11.6463918 7.0564433,11.6492268 L7.71417526,11.6492268 L6.16907216,15.3829897 C6.14072165,15.4652062 6.12371134,15.5502577 6.12371134,15.6381443 C6.12371134,15.8479381 6.18891753,16.0123711 6.30515464,16.1399485 C6.42139175,16.2675258 6.58865979,16.3298969 6.79845361,16.3298969 C6.93737113,16.3270619 7.07061856,16.2845361 7.18118557,16.2023196 C7.30025773,16.1201031 7.38247423,16.0010309 7.44768041,15.8649485 L9.14871134,11.6463918 L10.3280928,11.6463918 C10.6938144,11.6435567 10.9914948,11.3487113 10.9943299,10.9801546 L10.9943299,10.9631443 C10.9914948,10.5974227 10.6938144,10.2997423 10.3280928,10.2969072 L9.69871134,10.2969072 L10.9971649,7.0621134 L14.5353093,15.8621134 C14.6005155,15.9981959 14.682732,16.117268 14.8018041,16.1994845 C14.9123711,16.281701 15.0484536,16.3242268 15.1845361,16.3270619 C15.3943299,16.3270619 15.5587629,16.2646907 15.6778351,16.1371134 C15.7969072,16.0095361 15.8592784,15.8451031 15.8592784,15.6353093 C15.8621134,15.5502577 15.8479381,15.4623711 15.8139175,15.3829897 Z" id="Path" fill="#FFFFFF"></path>
                    </g>
                </g>
            </svg>
		);
	}
}

export default AaveLogoMd